import Phaser, { Scene } from "phaser";
import { TYRAGA_CONTAINER_ID } from "./constants";

export const getGameConfig = (scenes: Scene[]): Phaser.Types.Core.GameConfig => ({
  type: Phaser.AUTO,
  parent: TYRAGA_CONTAINER_ID,
  scene: scenes,
  transparent: true,
  scale: {
    mode: Phaser.Scale.FIT,
    // height: "100%",
    width: "100%",
  },
  physics: {
    default: "arcade",
    arcade: {
      debug: import.meta.env.DEV,
    },
  },
  canvasStyle: "width: 100%;height:100%;margin-top:40px;",
  maxTextures: -1,
});
