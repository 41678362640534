import { PropsWithChildren, useEffect } from "react";
import { qa } from "@isource-dev/testing";
import { PUSH_NEW_PAGE_EVENT } from "~/shared/routes";
import { pageStyles } from "./styles.css";

export const Page = ({ children }: PropsWithChildren) => {
  useEffect(() => {
    if (window.history.state === PUSH_NEW_PAGE_EVENT) {
      return;
    }

    document.body.style.background = "";
  }, [window.history.state]);

  return (
    <div {...qa("page")} className={pageStyles}>
      {children}
    </div>
  );
};
