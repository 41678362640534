import { makeAutoObservable } from "mobx";
import { User } from "@isource-ton/backends.turyaga";
import { IStoreUnit } from "~/shared/types";
import { RootStore } from "~/app/config";

export class PlayerStore implements IStoreUnit {
  root: RootStore;

  private user: User | null = null;

  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  setUser(data: User | undefined) {
    this.user = data ?? null;
  }

  get state() {
    return {
      user: this.user,
    };
  }

  reset() {
    this.user = null;
  }
}
