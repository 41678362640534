import { retrieveLaunchParams } from "@telegram-apps/sdk";
import axios from "axios";

const getSafeLaunchParams = () => {
  try {
    const { initDataRaw } = retrieveLaunchParams();
    return initDataRaw;
  } catch (e) {
    return JSON.stringify({});
  }
};

const buildClient = () => {
  const initData = getSafeLaunchParams();

  return axios.create({
    baseURL: import.meta.env.VITE_API_BASE_URL,
    headers: {
      "X-Telegram-InitData": initData,
    },
  });
};

export const api = buildClient();

export enum QueryKeysEnum {
  Player = "Player",
  Inventory = "Inventory",
  Bosses = "Bosses",
  Referral = "referral",
  Shared = "Shared"
}
