import { Flex } from "@mantine/core";
import { useEffect } from "react";
import { useViewport } from "@telegram-apps/sdk-react";
import { useLocation } from "react-router-dom";
import { AsideMenu } from "~/layouts/components/AsideMenu";
import { GameWrapper } from "~/domains/game/engine/GameWrapper";
import imageMainPageBg from "~/shared/assets/main-page-bg.png";
import { DEFAULT_PAGE_PADDING } from "~/shared/theme";
import { usePageSettings } from "~/layouts/context";
import { GameRoutes } from "~/shared/routes";

export const RootGamesPage = () => {
  const viewport = useViewport();
  const { setPageSettings } = usePageSettings();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== GameRoutes.Root) {
      return;
    }
    setPageSettings({
      backgroundImage: `url(${imageMainPageBg})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    });
  }, [viewport, setPageSettings, location]);

  return (
    <>
      <Flex
        align='flex-start'
        justify='flex-end'
        left={DEFAULT_PAGE_PADDING}
        pos='fixed'
        right={DEFAULT_PAGE_PADDING}
        style={{ zIndex: 10 }}
        top={0}
      >
        <AsideMenu />
      </Flex>

      <Flex direction='column' h='100%' justify='flex-end'>
        <GameWrapper />
      </Flex>
    </>
  );
};
