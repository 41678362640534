import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useLaunchParams } from "@telegram-apps/sdk-react";
import { QueryKeysEnum } from "~/shared/api";
import { userBackend } from "~/shared/backends";
import { useAppStore } from "~/app/config";
import { parseReferralCodeFromURL } from "~/domains/referral/lib";
import { useSharedInfo } from "~/shared/hooks";

const useGetPlayerQuery = () => useQuery({
  queryFn: async () => {
    const { data } = await userBackend.getUserInformation();
    return data.response;
  },
  queryKey: [QueryKeysEnum.Player],
});

const useCreatePlayerQuery = (referralCode: string | null, enabled?: boolean) => useQuery({
  queryFn: async () => {
    const { data } = await userBackend.createUser(undefined, undefined, {
      referralCode,
    });
    return data;
  },
  queryKey: [QueryKeysEnum.Player],
  enabled,
});

export const useCheckUser = () => {
  const { playerStore } = useAppStore();
  const { isError: userNotExistsFlag, data, refetch: refetchUserInfo } = useGetPlayerQuery();
  const { initData } = useLaunchParams();

  const referralCode = parseReferralCodeFromURL(initData?.startParam);

  const { data: createUserResponse } = useCreatePlayerQuery(referralCode, userNotExistsFlag);
  const { refetch: refetchSharedInfo } = useSharedInfo(!userNotExistsFlag);

  useEffect(() => {
    if (data) {
      playerStore.setUser(data);
    }
  }, [data]);

  useEffect(() => {
    if (createUserResponse?.status === "200") {
      Promise.all([
        refetchUserInfo(),
        refetchSharedInfo(),
      ]);
    }
  }, [createUserResponse, refetchUserInfo, refetchSharedInfo]);
};
