import { Box, Flex, rem } from "@mantine/core";
import { FormattedMessage } from "react-intl";
import { Drawer } from "vaul";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Title } from "~/shared/components";
import IconClose from "~/shared/assets/close.svg?react";
import { MilestoneType, ProgressBar } from "~/domains/referral/components/Popup/components/ProgressBar";
import { referralBackend } from "~/shared/backends";
import { QueryKeysEnum } from "~/shared/api";
import { ReferralCode } from "../ReferralCode";
import IconReff from "./assets/ReffIcon.svg";
import { InvitedFriends } from "./InvitedFriends";
import {
  enableClickStyles,
  mainPopupContentStyles,
  basePopupOverlayStyles, mainBodyPopupStyles,
} from "./styles.css";
import prizeFirstIcon from "./assets/FirstPrize.png";
import prizeSecondIcon from "./assets/SecondPrize.png";
import prizeThirdIcon from "./assets/ThirdPrize.png";
import prizeNotCompletedIcon from "./assets/NotCompletedPrize.png";

const milestones: MilestoneType[] = [{
  requireNumber: 3,
  imagePrize: prizeFirstIcon,
  countPrize: 1,
  imagePrizeNotCompleted: prizeNotCompletedIcon,
  name: "referral-popup.loot",
}, {
  requireNumber: 10,
  imagePrize: prizeSecondIcon,
  countPrize: 1,
  imagePrizeNotCompleted: prizeNotCompletedIcon,
  name: "referral-popup.loot",
}, {
  requireNumber: 30,
  imagePrize: prizeThirdIcon,
  countPrize: 1,
  imagePrizeNotCompleted: prizeNotCompletedIcon,
  name: "referral-popup.loot",
}];

const SIZE = rem(80);

type TriggerProps = {
  onClick: () => void
}

const Trigger = ({ onClick }: TriggerProps) => {
  return (
    <Flex
      align="center"
      direction='column'
      h={SIZE}
      justify='flex-end'
      pos='relative'
      w={SIZE}
      onClick={onClick}
    >
      <Title
        bottom={rem(12)}
        fz={rem(20)}
        lh={rem(20)}
        pos="relative"
        variant='inverted'
        style={{
          zIndex: 1,
          transform: "rotate(-4deg)",
        }}
      >
        <FormattedMessage id='referral-popup.trigger-text' />
      </Title>

      <Box
        fz={rem(20)}
        h={SIZE}
        pos="absolute"
        ta="center"
        w={SIZE}
        style={{
          backgroundImage: `url(${IconReff})`,
          backgroundSize: "100% 100%",
        }}
      />
    </Flex>
  );
};

export const ReferralPopup = () => {
  const [open, onOpenChange] = useState(false);
  const [isNestedMaxed, setIsNestedMaxed] = useState(false);
  const [shouldNestedClose, setShouldNestedClose] = useState(false);

  const { data: referralData } = useQuery({
    queryFn: async () => {
      const { data } = await referralBackend.getUserReferralsInfo();
      return data.response;
    },
    queryKey: [QueryKeysEnum.Referral],
  });

  const progress = typeof referralData?.referralsCount === "number" ? referralData?.referralsCount : 0;

  const handleOpen = () => onOpenChange(true);
  const handleClose = () => {
    setShouldNestedClose(true);
    onOpenChange(false);
  };

  const handleSnapChange = (isMax: boolean) => setIsNestedMaxed(isMax);

  useEffect(() => {
    if (!open) {
      setShouldNestedClose(false);
    }
  }, [open]);

  return (
    // eslint-disable-next-line react/no-unknown-property
    <div vaul-drawer-wrapper="">
      <Drawer.Root
        noBodyStyles
        open={open}
        setBackgroundColorOnScale={false}
        onOpenChange={onOpenChange}
      >
        <Drawer.Trigger asChild>
          <Trigger onClick={handleOpen} />
        </Drawer.Trigger>

        <Drawer.Portal>
          <Drawer.Overlay className={basePopupOverlayStyles} style={{
            backdropFilter: "blur(10px)",
            height: "calc(100% + 1px)",
            WebkitBackdropFilter: "blur(10px)",
          }} />

          <Drawer.Content className={mainPopupContentStyles} style={{
            zIndex: isNestedMaxed ? 10 : 11,
          }}>
            <Box className={mainBodyPopupStyles}>
              <ProgressBar
                progress={progress <= 30 ? progress : 30}
                milestones={milestones.map(item => ({
                  ...item,
                  isCompleted: item.requireNumber <= progress,
                }))}
              />

              <Box bottom={rem(-52)} pos="relative">
                <Flex align="center" justify="space-between">
                  <Title style={{ transform: "rotate(-4deg)" }}>
                    <FormattedMessage id="referral-popup.main-title" />
                  </Title>

                  <button className={enableClickStyles}>
                    <IconClose onClick={handleClose} />
                  </button>
                </Flex>

                <ReferralCode />
              </Box>

              <InvitedFriends moveToFinalSnap={shouldNestedClose} onSnapChange={handleSnapChange} />
            </Box>
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.Root>
    </div>
  );
};
