import { Box, Flex, rem } from "@mantine/core";
import { Drawer } from "vaul";
import { useEffect, useState } from "react";
import { notifications } from "@mantine/notifications";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Title } from "~/shared/components";
import IconClose from "~/shared/assets/close.svg?react";
import {
  basePopupOverlayStyles,
  enableClickStyles,
  mainBodyPopupStyles,
  mainPopupContentStyles,
  triggerButtonContentContainerStyles,
  triggerButtonStyles,
} from "~/domains/referral/components/Popup/components/ProgressBar/components/ClaimPopup/styles.css";
import {
  ClaimContent,
} from "~/domains/referral/components/Popup/components/ProgressBar/components/ClaimPopup/components/ClaimContent";
import { referralBackend } from "~/shared/backends";
import checkoutImg from "./assets/Checkout.png";

type TriggerProps = {
  onClick: () => void
  isClaimed?: boolean
}

const Trigger = ({ isClaimed }: TriggerProps) => {
  const intl = useIntl();

  const handleTriggerClick = () => {
    if (isClaimed) {
      notifications.show({
        color: "gray",
        message: intl.formatMessage({ id: "claim-popup.notification" }),
      });
      return;
    }
    //
    // onClick();
  };

  return (
    <Button className={triggerButtonStyles} disabled={true} variant='green' onClick={handleTriggerClick}>
      <div className={triggerButtonContentContainerStyles}>
        <div style={{
          background: `url(${checkoutImg})`,
          backgroundSize: "cover",
          height: "13px",
          width: "14px",
        }} />
        <FormattedMessage id='claim-popup.claim' />
      </div>
    </Button>
  );
};

interface ClaimPopupProps {
  progress: number
}

export const ClaimPopup = ({ progress }: ClaimPopupProps) => {
  const [open, onOpenChange] = useState(false);
  const [claimed, setClaimed] = useState(false);

  useEffect(() => {
    referralBackend.checkNftClaimed().then(({ data }) => {
      const isClaimed = data.response;

      if (isClaimed) {
        setClaimed(true);
        return;
      }
    });
  }, []);

  const handleOpen = () => {
    onOpenChange(true);
  };
  const handleClose = () => {
    onOpenChange(false);
  };

  const level = () => {
    if (progress < 10) return 1;
    if (progress < 30) return 2;
    if (progress >= 30) return 3;
  };

  return (
    // eslint-disable-next-line react/no-unknown-property
    <div vaul-drawer-wrapper="" style={{ position: "absolute",
      left: "-100%",
      top: "11px",
    }}>
      <Drawer.NestedRoot
        handleOnly
        noBodyStyles
        modal={false}
        open={open}
        setBackgroundColorOnScale={false}
        onOpenChange={onOpenChange}
      >
        <Drawer.Trigger>
          <Trigger isClaimed={claimed} onClick={handleOpen} />
        </Drawer.Trigger>

        <Drawer.Portal>
          <Drawer.Overlay className={basePopupOverlayStyles} style={{
            backdropFilter: "blur(10px)",
            height: "calc(100% + 1px)",
            WebkitBackdropFilter: "blur(10px)",
          }} />

          <Drawer.Content className={mainPopupContentStyles}>
            <Box className={mainBodyPopupStyles}>
              <Box bottom={rem(-52)} pos="relative">
                <Flex align="center" justify="space-between">
                  <Title style={{ transform: "rotate(-4deg)" }}>

                  </Title>

                  <button className={enableClickStyles}>
                    <IconClose onClick={handleClose} />
                  </button>
                </Flex>
                <ClaimContent isCanClaim level={level() as number} onClose={handleClose} />
              </Box>
            </Box>
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.NestedRoot>
    </div>
  );
};
