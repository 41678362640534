import { ComponentProps, forwardRef, PropsWithChildren } from "react";
import clsx from "clsx";
import { buttonStyles, disableButtonStyles, skewedSpanStyles } from "./styles.css";

type Props = PropsWithChildren<{
  /**
   * Вид кнопки
   * @default blue
   */
  variant?: "blue" | "green" | "purple" | "skewed" | "blue-alpha" | "red"
}> & ComponentProps<"button">;

export const Button = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const {
    variant = "blue",
    children,
    className,
    disabled,
    ...rest
  } = props;

  return (
    <button {...rest} ref={ref} className={clsx(buttonStyles[variant], className, {
      [disableButtonStyles]: disabled,
    })}>
      <span className={clsx(variant === "skewed" && skewedSpanStyles)}>
        {children}
      </span>
    </button>
  );
});
Button.displayName = "Button";
