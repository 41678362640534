import { useMemo } from "react";
import clsx from "clsx";
import { Milestone } from "./components/Milestone";
import {
  baseProgressBarStyles,
  progressBarLineStyles,
} from "./styles.css";
import {
  CurrentProgressLine,
} from "./components/CurrentProgressLine";

const currentProgress = [
  {
    startColor: "#9FE5FF",
    endColor: "#ACFFE1",
  },
  {
    startColor: "#ACFFE1",
    endColor: "#FDFFAC",
  },
  {
    startColor: "#FDFFAC",
    endColor: "#FFBBAC",
  },
];

export interface MilestoneType {
  imagePrize: string,
  imagePrizeNotCompleted: string,
  countPrize: number,
  requireNumber: number,
  isCompleted?: boolean,
  name: string
}

interface ProgressBarProps {
  progress: number,
  milestones: MilestoneType[],
}

export const ProgressBar = ({ progress, milestones }: ProgressBarProps) => {
  const mileStoneSplice = useMemo(() => {
    return milestones.filter((item) => item.isCompleted).length + 1;
  }, []);

  return (
    <div className={clsx(baseProgressBarStyles)}>
      <div className={clsx(progressBarLineStyles.default, {
        [progressBarLineStyles.completed]: progress >= (milestones.at(-1)?.requireNumber ?? 0),
      })} />
      {currentProgress.slice(0, mileStoneSplice).map((item, index) => {
        return (
          <CurrentProgressLine
            percent={milestones[index].isCompleted
              ? index < 2 ? 15 : 25
              : progress / milestones[index].requireNumber * 50}
            {...item}
            index={index + 1}
            key={index}
          />
        );
      },
      )}
      {milestones.map((item, index) => (
        <Milestone
          {...item}
          index={index + 1}
          isCurrent={mileStoneSplice === index + 2}
          isNext={mileStoneSplice === index + 1}
          key={index}
          progress={progress}
        />
      ))}
    </div>
  );
};
