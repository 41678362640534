import { useInitData } from "@telegram-apps/sdk-react";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { useMemo } from "react";
import { IntlProvider } from "react-intl";
import { MantineProvider } from "@mantine/core";
import { QueryClientProvider } from "@tanstack/react-query";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import { AppRouter, queryClient, StoreProvider } from "~/app/config";
import { theme } from "~/shared/theme";
import { ConnectedWalletProvider } from "~/domains/ton-auth/features/connect-wallet/connected-wallet.provider";

import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "./index.css";

import "../shared/mockEnv.ts";

function App() {
  const initData = useInitData();

  const manifestUrl = useMemo(() => {
    const host = import.meta.env.DEV ? import.meta.env.VITE_MANIFEST_URL : window.location.href;

    return new URL("tonconnect-manifest.json", host).toString();
  }, []);

  const localeParams = useMemo(() => {
    return {
      locale: initData?.user?.languageCode === "ru" ? "ru" : "en",
    };
  }, [initData]);

  return (
    <IntlProvider defaultLocale='ru' locale={localeParams.locale} messages={translations[localeParams.locale]}>
      <MantineProvider theme={theme}>
        <ModalsProvider>
          <TonConnectUIProvider manifestUrl={manifestUrl}>
            <QueryClientProvider client={queryClient}>
              <StoreProvider>
                <ConnectedWalletProvider>
                  <AppRouter />
                </ConnectedWalletProvider>
              </StoreProvider>
            </QueryClientProvider>
          </TonConnectUIProvider>
        </ModalsProvider>
        <Notifications limit={3} />
      </MantineProvider>
    </IntlProvider>
  );
}

export default App;
