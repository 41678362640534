import { useEffect } from "react";
import * as Phaser from "phaser";
import { BootScene } from "../scenes/boot.scene";
import { MainScene } from "../scenes/main.scene";
import { gameWrapperStyles } from "./styles.css";
import { getGameConfig } from "./lib";
import { TYRAGA_CONTAINER_ID } from "./constants";

export const GameWrapper = () => {
  useEffect(() => {
    const scenes = [
      new BootScene(),
      new MainScene(),
    ];

    const config = getGameConfig(scenes);
    const _game = new Phaser.Game(config);

    return () => {
      _game.destroy(true);
    };
  }, []);

  return (
    <div
      className={gameWrapperStyles}
      id={TYRAGA_CONTAINER_ID}
    />
  );
};
