import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import { ClaimPopup } from "~/domains/referral/components/Popup/components/ProgressBar/components/ClaimPopup";
import { MilestoneType } from "../../../ProgressBar";
import {
  backgroundPrizeNextStyles,
  backgroundPrizeNotCompletedFilterStyles,
  backgroundPrizeStyles,
  baseBackgroundPrizeStyles,
  baseRequireTextStyles,
  containerRequireStyles,
  countPrizeStyles, friendCompletedIconStyles,
  friendIconStyles,
  mileStoneCompleteImageStyles,
  mileStoneCompleteStyles,
  mileStoneImageStyles,
  milestoneStyles, namePrizeNotCompletedStyles,
  namePrizeStyles,
  prizeCountNameContainerStyles,
  prizeIconStyles,
  requireTextCompletedStyles,
  requireTextStyles,
} from "./styles.css";

interface MilestoneProps extends MilestoneType {
  index: number
  isNext?: boolean
  isCurrent?: boolean
  progress: number
}

export const Milestone = ({
  countPrize,
  requireNumber,
  index,
  isCompleted,
  isNext,
  imagePrizeNotCompleted,
  imagePrize,
  name,
  isCurrent,
  progress,
}: MilestoneProps) => {
  return (
    <div className={clsx({
      [milestoneStyles[index]]: !isCompleted,
      [mileStoneCompleteStyles[index]]: isCompleted,
    })}>
      <div className={clsx({
        [mileStoneImageStyles[index]]: !isCompleted,
        [mileStoneCompleteImageStyles[index]]: isCompleted,
      })}>
        <div className={containerRequireStyles}>
          <span className={clsx(baseRequireTextStyles, {
            [requireTextStyles[index]]: !isCompleted,
            [requireTextCompletedStyles[index]]: isCompleted,
          })}>{requireNumber}</span>
          <div className={clsx({
            [friendIconStyles[index]]: !isCompleted,
            [friendCompletedIconStyles]: isCompleted,
          })} />
        </div>
      </div>
      <div className={clsx(baseBackgroundPrizeStyles, {
        [backgroundPrizeNextStyles]: isNext,
      })}>
        <div className={clsx(backgroundPrizeStyles[index], {
          [backgroundPrizeNotCompletedFilterStyles]: !isCompleted && !isNext,
        })}>
          <img className={prizeIconStyles} src={(!isCompleted && !isNext) ? imagePrizeNotCompleted : imagePrize} />
          {isNext && <div className={prizeCountNameContainerStyles}>
            <span className={countPrizeStyles}>x{countPrize}</span>
            <span className={namePrizeStyles}><FormattedMessage id={name} /></span>
          </div>}
          {(!isCompleted && !isNext) && <span className={namePrizeNotCompletedStyles}> ??? </span>}
        </div>
      </div>
      {isCurrent && <ClaimPopup progress={progress} />}
    </div>
  );
};
