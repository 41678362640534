import { Flex } from "@mantine/core";
import { FormattedMessage } from "react-intl";
import { useUtils } from "@telegram-apps/sdk-react";
import { Button } from "~/shared/components";
import { useAppStore } from "~/app/config";
import { REFERRAL_KEY_ID } from "~/shared/constants";
import { buttonsContainerStyles, buttonStyles, codeContainerStyles, codeStyles } from "./styles.css";

export const ReferralCode = () => {
  const utils = useUtils();
  const { playerStore } = useAppStore();

  const userReferralCode = playerStore.state.user?.referralData?.referralCode;

  const handleShare = () => utils.openTelegramLink(
    // eslint-disable-next-line max-len
    `https://t.me/share/url?url=https://t.me/${import.meta.env.VITE_BOT_ID}?startapp=${REFERRAL_KEY_ID}${userReferralCode}`,
  );

  const handleCopy = () => {
    if (userReferralCode && window.navigator.clipboard) {
      // eslint-disable-next-line max-len
      window.navigator.clipboard.writeText(`https://t.me/${import.meta.env.VITE_BOT_ID}?startapp=${REFERRAL_KEY_ID}${userReferralCode}`);
    }
  };

  return (
    <>
      <Flex align='center' className={codeContainerStyles} justify="center" mt='lg'>
        <span className={codeStyles}>
          {userReferralCode}
        </span>
      </Flex>

      <Flex
        align="center"
        className={buttonsContainerStyles}
        justify="center"
        mx='auto'
        pos='absolute'
        w="100%"
      >
        <Button
          className={buttonStyles}
          variant="purple"
          onClick={handleCopy}
          onMouseDown={(e) => e.stopPropagation()}
          onPointerMove={(e) => e.stopPropagation()}
          onTouchStart={(e) => e.stopPropagation()}
        >
          <FormattedMessage id="referral-code-user.copy" />
        </Button>

        <Button
          className={buttonStyles}
          variant='blue'
          onClick={handleShare}
          onMouseDown={(e) => e.stopPropagation()}
          onPointerMove={(e) => e.stopPropagation()}
          onTouchStart={(e) => e.stopPropagation()}
        >
          <FormattedMessage id="referral-code-user.share" />
        </Button>
      </Flex>
    </>
  );
};
