import { Box, Flex, rem } from "@mantine/core";
import { FormattedMessage } from "react-intl";
import { Title } from "~/shared/components";
import { NotAvailablePopover } from "~/shared/NotAvailablePopover";
import IconDailyTask from "./assets/TasksIcon.svg";
import ChainsIcon from "./assets/chains.svg";

const SIZE = rem(80);

const Trigger = ({ onClick }: { onClick?: () => void }) => (
  <NotAvailablePopover>
    <Box pos='relative'>
      <Flex
        align="center"
        direction='column'
        h={SIZE}
        justify='flex-end'
        pos='relative'
        w={SIZE}
        style={{
          filter: "contrast(.35)",
        }}
        onClick={onClick}
      >
        <Title
          bottom={rem(12)}
          fz={rem(20)}
          lh={rem(20)}
          pos="relative"
          variant='inverted'
          style={{
            zIndex: 1,
            transform: "rotate(-4deg)",
          }}
        >
          <FormattedMessage id='daily-tasks.trigger-text' />
        </Title>

        <Box
          fz={rem(20)}
          h={SIZE}
          pos="absolute"
          ta="center"
          w={SIZE}
          style={{
            backgroundImage: `url(${IconDailyTask})`,
            backgroundSize: "100% 100%",
          }}
        />
      </Flex>
      <span style={{
        backgroundImage: `url(${ChainsIcon})`,
        position: "absolute",
        width: "110%",
        height: "110%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "80%",
        backgroundPosition: "center",
        top: rem(-8),
        left: rem(-4),
        filter: "contrast(0.5)",
      }} />
    </Box>
  </NotAvailablePopover>
);

export const DailyPopup = () => {
  return (
    <>
      <Trigger />
      {/*<Drawer.Root*/}
      {/*  noBodyStyles*/}
      {/*  open={open}*/}
      {/*  setBackgroundColorOnScale={false}*/}
      {/*  onOpenChange={onOpenChange}*/}
      {/*>*/}
      {/*  <Drawer.Trigger asChild>*/}
      {/*    <Trigger onClick={handleOpen} />*/}
      {/*  </Drawer.Trigger>*/}

      {/*  <Drawer.Portal>*/}
      {/*    <Drawer.Overlay className={popupOverlayStyles} />*/}

      {/*    <Drawer.Content className={popupStyles}>*/}
      {/*      <Flex align="center" justify="space-between" p={vars.other.pageXOffset}>*/}
      {/*        <Title*/}
      {/*          bottom={rem(-24)}*/}
      {/*          lh={rem(30)}*/}
      {/*          pos="relative"*/}
      {/*          style={{ transform: "rotate(-4deg)" }}*/}
      {/*          w={rem(100)}*/}
      {/*        >*/}
      {/*          <FormattedMessage id="daily-tasks.main-title" />*/}
      {/*        </Title>*/}

      {/*        <Box bottom={rem(-24)} className={enableClickStyles} pos="relative" right={rem(-10)}>*/}
      {/*          <IconClose onClick={handleClose} />*/}
      {/*        </Box>*/}
      {/*      </Flex>*/}

      {/*      <DailyTasksList />*/}
      {/*    </Drawer.Content>*/}
      {/*  </Drawer.Portal>*/}
      {/*</Drawer.Root>*/}
    </>
  );
};
