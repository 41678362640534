import { AssetsKeysEnum } from "~/domains/game/engine/constants";
import { bodyAssetAtlas, bodyAssetPng } from "~/domains/game/engine/assets/body";
import { leftArmAssetAtlas, leftArmAssetPng } from "~/domains/game/engine/assets/left-arm";
import { rightArmAssetAtlas, rightArmAssetPng } from "~/domains/game/engine/assets/right-arm";
import { pantsAssetAtlas, pantsAssetPng } from "~/domains/game/engine/assets/pants";
import { leftLegAssetAtlas, leftLegAssetPng } from "~/domains/game/engine/assets/left-leg";
import { rightLegAssetAtlas, rightLegAssetPng } from "~/domains/game/engine/assets/right-leg";
import { hatAssetPng } from "~/domains/game/engine/assets/hat";
import { headAssetPng } from "~/domains/game/engine/assets/head";
import { chetkiAssetPng } from "~/domains/game/engine/assets/chetki";

export class BootScene extends Phaser.Scene {
  constructor() {
    super("BootScene");
  }

  create() {
    this.scene.start("MainScene");
  }

  preload() {
    this._loadAtlas();
    this._loadImages();
  }

  private _loadAtlas() {
    this.load.atlas(AssetsKeysEnum.Body, bodyAssetPng, bodyAssetAtlas);

    this.load.atlas(AssetsKeysEnum.LeftArm, leftArmAssetPng, leftArmAssetAtlas);
    this.load.atlas(AssetsKeysEnum.RightArm, rightArmAssetPng, rightArmAssetAtlas);

    this.load.atlas(AssetsKeysEnum.Pants, pantsAssetPng, pantsAssetAtlas);

    this.load.atlas(AssetsKeysEnum.LeftLeg, leftLegAssetPng, leftLegAssetAtlas);
    this.load.atlas(AssetsKeysEnum.RightLeg, rightLegAssetPng, rightLegAssetAtlas);
  }

  private _loadImages() {
    this.load.image(AssetsKeysEnum.Hat, hatAssetPng);
    this.load.image(AssetsKeysEnum.Head, headAssetPng);
    this.load.image(AssetsKeysEnum.Chetki, chetkiAssetPng);
  }
}
