import Phaser from "phaser";
import { AssetsKeysEnum } from "../constants";

const SCALE = .33;

export class MainScene extends Phaser.Scene {
  hat?: Phaser.GameObjects.Image;
  head?: Phaser.GameObjects.Image;
  chetki?: Phaser.GameObjects.Image;

  body?: Phaser.GameObjects.Sprite;

  leftArm?: Phaser.GameObjects.Sprite;
  rightArm?: Phaser.GameObjects.Sprite;

  pants?: Phaser.GameObjects.Sprite;

  leftLeg?: Phaser.GameObjects.Sprite;
  rightLeg?: Phaser.GameObjects.Sprite;

  constructor() {
    super("MainScene");
  }

  create() {
    const { width: sceneWidth, height: sceneHeight } = this.cameras.main;
    const width = sceneWidth / 2;
    const height = sceneHeight / 2;

    // Порядок добавления спрайтов важен

    this.body = this.add.sprite(width, height, AssetsKeysEnum.Body, "body").setScale(SCALE);

    this.head = this.add.image(width, height, AssetsKeysEnum.Head).setScale(SCALE);
    this.hat = this.add.sprite(width, height, AssetsKeysEnum.Hat).setScale(SCALE);

    this.pants = this.add.sprite(width, height, AssetsKeysEnum.Pants).setScale(SCALE);

    this.leftArm = this.add.sprite(width, height, AssetsKeysEnum.LeftArm).setScale(SCALE);
    this.rightArm = this.add.sprite(width, height, AssetsKeysEnum.RightArm, "right-arm").setScale(SCALE);

    this.leftLeg = this.add.sprite(width, height, AssetsKeysEnum.LeftLeg, "left-leg").setScale(SCALE);
    this.rightLeg = this.add.sprite(width, height, AssetsKeysEnum.RightLeg, "right-leg").setScale(SCALE);

    this.chetki = this.add.image(width, height, AssetsKeysEnum.Chetki).setScale(SCALE);
  }
}
