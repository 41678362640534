import { useTonAddress, useTonConnectModal, useTonConnectUI } from "@tonconnect/ui-react";
import { useCallback } from "react";

export const useConnectWallet = () => {
  const { open } = useTonConnectModal();
  const [tonConnectUI] = useTonConnectUI();
  const walletAddress = useTonAddress();

  const connect = useCallback(async () => {
    if (walletAddress) {
      return;
    }

    else {
      open();
    }
  }, [open, tonConnectUI, walletAddress]);

  return {
    connect,
    isConnected: !!walletAddress,
  };
};
