import { Box, Popover, rem, Text } from "@mantine/core";
import { useUtils } from "@telegram-apps/sdk-react";
import { PropsWithChildren } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../components";
import { arrowStyles, buttonStyles, popoverStyles } from "./styles.css";

type Props = {
  available?: boolean;
}

export const NotAvailablePopover = ({ children, available = true }: PropsWithChildren<Props>) => {
  const utils = useUtils();

  const handleChannelRedirection = () => {
    utils.openTelegramLink("https://t.me/TonPrisonNews");
  };

  if (!available) {
    return children;
  }

  return (
    <Popover
      trapFocus
      withArrow
      arrowPosition="side"
      offset={24}
      position="top"
      classNames={{
        dropdown: popoverStyles,
        arrow: arrowStyles,
      }}
    >
      <Popover.Target>
        {children}
      </Popover.Target>
      <Popover.Dropdown>
        <Box pos='relative'>
          <Text
            ff='monospace'
            fw={700}
            fz={rem(12)}
            lh={rem(14)}
            pb={rem(12)}
            pt={rem(12)}
            style={{ color: "#FFFFFFCC" }}
          >
            <FormattedMessage id='not-available.text' />
          </Text>
          <Button
            className={buttonStyles}
            variant='blue-alpha'
            onClick={handleChannelRedirection}
          >
            <FormattedMessage id='not-available.channel' />
          </Button>
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
};
