import { createContext, PropsWithChildren, useContext } from "react";
import { observer } from "mobx-react-lite";
import { RootStore } from "./store";

let store: RootStore;
function initializeStore() {
  const _store = store ?? new RootStore();
  if (typeof window === "undefined") return _store;
  if (!store) store = _store;

  return _store;
}

export const StoreContext = createContext({} as RootStore);

export const useAppStore = () => useContext(StoreContext);

export const StoreProvider = observer(({ children }: PropsWithChildren) => {
  const store = initializeStore();

  return (
    <StoreContext.Provider value={store}>
      {children}
    </StoreContext.Provider>
  );
});
