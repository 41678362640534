import { Text, TextProps, rem } from "@mantine/core";
import clsx from "clsx";
import { PropsWithChildren } from "react";
import { textStyles } from "./styles.css";

type Props = PropsWithChildren<TextProps>

export const OutlineText = (props: Props) => {
  return (
    <Text
      fw={700}
      fz={rem(20)}
      lh='normal'
      {...props}
      className={clsx(textStyles, props.className)}
    >
      {props.children}
    </Text>
  );
};
