import { useInitData } from "@telegram-apps/sdk-react";
import { useTonAddress } from "@tonconnect/ui-react";
import { observer } from "mobx-react-lite";
import { PropsWithChildren, useEffect } from "react";
import { useAppStore } from "~/app/config";
import { useBackendAuth } from "./useBackendAuth";

export const ConnectedWalletProvider = observer(
  ({ children }: PropsWithChildren) => {
    const walletAddress = useTonAddress();
    const { tonWalletStore } = useAppStore();
    const initData = useInitData();
    const { backendToken } = tonWalletStore.state;

    useBackendAuth();

    useEffect(() => {
      if (walletAddress && backendToken) {
        tonWalletStore.setData({
          address: walletAddress,
          name: initData?.user?.firstName ?? "",
          username: initData?.user?.username ?? "",
          referral_code: initData?.startParam,
        });
      }
    }, [backendToken, initData, tonWalletStore]);

    return children;
  });
