import { createContext, CSSProperties, PropsWithChildren, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useViewport } from "@telegram-apps/sdk-react";
import { GameRoutes } from "~/shared/routes";

type ContextType = {
  setPageSettings: (settings: CSSProperties) => void
}

const PageSettingsContext = createContext({} as ContextType);

const INITIAL_ROUTES = [
  GameRoutes.Root,
  GameRoutes.Inventory,
  // ActivitiesRoutes.Root,
  // MarketRoutes.Root,
];

export const PageSettingsProvider = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const viewport = useViewport();

  const updateSettings = (settings: CSSProperties) => {
    setTimeout(() => {
      document.body.removeAttribute("style");

      for (const [property, value] of Object.entries(settings)) {
        document.body.style[property] = value;
      }
    });
  };

  useEffect(() => {
    const path = location.pathname || "/";
    if (INITIAL_ROUTES.includes(path)) {
      return;
    }

    document.body.removeAttribute("style");
  }, [viewport, location.pathname]);

  return (
    <PageSettingsContext.Provider value={{ setPageSettings: updateSettings }}>
      {children}
    </PageSettingsContext.Provider>
  );
};

export const usePageSettings = () => useContext(PageSettingsContext);
