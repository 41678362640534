import { Navigate, Route, Router, Routes } from "react-router-dom";
import { useEffect, useMemo } from "react";
import {
  bindMiniAppCSSVars,
  bindThemeParamsCSSVars,
  bindViewportCSSVars,
  initNavigator,
  useMiniApp,
  useThemeParams,
  useViewport,
} from "@telegram-apps/sdk-react";
import { useIntegration } from "@telegram-apps/react-router-integration";
import { GameRoutes } from "~/shared/routes/game.routes";
import { DefaultLayout } from "~/layouts/default.layout";
import { PageSettingsProvider } from "~/layouts/context/page-settings.context";

export const AppRouter = () => {
  const miniApp = useMiniApp();
  const themeParams = useThemeParams();
  const viewport = useViewport();

  useEffect(() => {
    return bindMiniAppCSSVars(miniApp, themeParams);
  }, [miniApp, themeParams]);

  useEffect(() => {
    return bindThemeParamsCSSVars(themeParams);
  }, [themeParams]);

  useEffect(() => {
    return viewport && bindViewportCSSVars(viewport);
  }, [viewport]);

  // Create new application navigator and attach it to the browser history, so it could modify
  // it and listen to its changes.
  const navigator = useMemo(() => initNavigator("app-navigation-state"), []);
  const [location, reactNavigator] = useIntegration(navigator);

  // Don't forget to attach the navigator to allow it to control the BackButton state as well
  // as browser history.
  useEffect(() => {
    navigator.attach();
    return () => navigator.detach();
  }, [navigator]);

  return (
    <Router location={location} navigator={reactNavigator}>
      <PageSettingsProvider>
        <DefaultLayout>
          <Routes>
            {/*{GameRoutesModule()}*/}
            {/*{MarketRoutesModule()}*/}
            {/*{ActivitiesRoutesModule()}*/}
            <Route element={<Navigate to={GameRoutes.Root} />} path='*' />
          </Routes>
        </DefaultLayout>
      </PageSettingsProvider>
    </Router>
  );
};
