import { Flex } from "@mantine/core";
import { DailyPopup } from "~/domains/game/components/DailyTasks";
import { ReferralPopup } from "~/domains/referral/components/Popup";

export const AsideMenu = () => {
  return (
    <Flex direction="column" gap='md' pos='relative'>
      <ReferralPopup />
      <DailyPopup />
    </Flex>
  );
};
