import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { qa } from "@isource-dev/testing";
import { Box, rem } from "@mantine/core";
import clsx from "clsx";
import { Title } from "~/shared/components";
import { NotAvailablePopover } from "~/shared/NotAvailablePopover";
import { BottomNavigationEnum, getTabs, SPIKES_INFO_MAP } from "./lib";
import { activeItemStyles, tabItemStyles, tabIconStyles, tabStyles, tabContainerStyles } from "./styles.css";

export const TabNavigation = () => {
  const intl = useIntl();
  // const navigate = useNavigate();
  const { pathname } = useLocation();
  // const { isConnected, connect } = useConnectWallet();

  const tabs = getTabs(intl);

  const [currentTab, setCurrentTab] = useState<BottomNavigationEnum>(tabs[0].id);
  const [isPressed, setIsPressed] = useState(false);

  // const handleTabClick = (id: BottomNavigationEnum, path: string) => {
  //   if (id === BottomNavigationEnum.TabFour && !isConnected) {
  //     connect();
  //     return;
  //   }
  //
  //   setCurrentTab(id);
  //   navigate(path, {
  //     replace: true,
  //   });
  // };

  const handleBounce = (id: BottomNavigationEnum) => {
    if (id !== BottomNavigationEnum.TabOne) {
      return;
    }

    setIsPressed(p => !p);
  };

  useEffect(() => {
    setCurrentTab(tabs.find(tab => pathname.endsWith(tab.path))?.id ?? tabs[0].id);
  }, [pathname]);

  return (
    <div className={tabContainerStyles}>
      {tabs.map(({ id, text, icon, textInlineStyles, iconInlineStyles, bg, tabInlineStyles }) => {
        const selected = id === currentTab;

        return (
          <NotAvailablePopover available={id !== BottomNavigationEnum.TabOne} key={id}>
            <span
              className={clsx(tabItemStyles, isPressed && id === BottomNavigationEnum.TabOne && activeItemStyles)}
              onTouchEnd={() => handleBounce(id)}
              onTouchStart={() => handleBounce(id)}
              {...qa(`tab-item-${id}`, { selected })}
            >
              <Box className={tabStyles} style={{
                ...tabInlineStyles,
                ...(id !== BottomNavigationEnum.TabOne && {
                  filter: "contrast(.35)",
                }),
                backgroundImage: `url(${bg})`,
              }}>
                <Title
                  fz={rem(28)}
                  lh={rem(28)}
                  mx='auto'
                  pos="relative"
                  right={rem(-4)}
                  variant='inverted'
                  style={{
                    ...textInlineStyles,
                    zIndex: 1,
                  }}
                >
                  {text}
                </Title>
                <Box
                  className={tabIconStyles}
                  pos="absolute"
                  style={{
                    ...iconInlineStyles,
                    backgroundImage: `url(${icon})`,
                  }}
                />
              </Box>

              <span style={SPIKES_INFO_MAP[id]} />
            </span>
          </NotAvailablePopover>
        );
      })}
    </div>
  );
};
