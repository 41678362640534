export enum AssetsKeysEnum {
  Hat = "hat",
  Head = "head",
  Body = "body",
  Chetki = "chetki",
  LeftArm = "left-arm",
  RightArm = "right-arm",
  Pants = "pants",
  LeftLeg = "left-leg",
  RightLeg = "right-leg"
}
