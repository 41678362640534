import { useQuery } from "@tanstack/react-query";
import { QueryKeysEnum } from "~/shared/api";
import { sharedBackend } from "~/shared/backends";

export const useSharedInfo = (enabled?: boolean) => useQuery({
  queryFn: async () => {
    const { data } = await sharedBackend.getUserSharedInfo();
    return data.response;
  },
  enabled,
  queryKey: [QueryKeysEnum.Shared],
});
