import { ActionIcon, Flex, rem } from "@mantine/core";
import IconBackNavigation from "~/shared/assets/back-arrow.svg?react";
import { PUSH_NEW_PAGE_EVENT } from "~/shared/routes";
import { vars } from "~/shared/theme";
import { PlayerLantern } from "~/domains/game/entities/player";
import { PlayerAvailableUnits } from "~/domains/game/entities/player/components/AvailableUnits";

export const TopNavbar = () => {
  const hasBackButton = window.history.state === PUSH_NEW_PAGE_EVENT;

  const handleBackNavigation = () => {
    window.history.back();
  };

  return (
    <Flex
      align="center"
      gap={rem(16)}
      justify="space-between"
      p={vars.other.pageXOffset}
    >
      {
        hasBackButton && (
          <ActionIcon variant="transparent" onClick={handleBackNavigation}>
            <IconBackNavigation />
          </ActionIcon>
        )
      }

      <PlayerLantern />

      <PlayerAvailableUnits />
    </Flex>
  );
};
