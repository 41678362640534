import { ActionIcon, Flex, rem, Text } from "@mantine/core";
import { qa } from "@isource-dev/testing";
import { shortenNumber } from "@isource-dev/formatters";
import IconCigarettes from "~/shared/assets/cigarettes.svg?react";
import IconTea from "~/shared/assets/tea.svg?react";
import IconEnergy from "~/shared/assets/energy.svg?react";
import IconPlus from "~/shared/assets/green-plus.svg?react";
import { useSharedInfo } from "~/shared/hooks";
import { iconStyles, itemContainerStyles } from "./styles.css";

export const PlayerAvailableUnits = () => {
  const { data } = useSharedInfo();

  return (
    <Flex align='flex-end' direction='column' flex={1} gap={rem(8)} justify="flex-end">
      <Flex align='center' className={itemContainerStyles} {...qa("crystals-balance")}>
        <IconEnergy className={iconStyles} />

        <Flex align="center" gap={rem(4)} justify='flex-end'>
          <Text fw={700} fz={rem(16)} lh={rem(16)}>
            {data?.energy?.currentEnergy} / {data?.energy?.maxEnergy}
          </Text>

          <ActionIcon mr={rem(2)} size={18} variant="transparent">
            <IconPlus />
          </ActionIcon>
        </Flex>
      </Flex>

      <Flex align='center' className={itemContainerStyles} {...qa("cigarettes-balance")}>
        <IconTea className={iconStyles} />

        <Flex align="center" gap={rem(4)} justify='flex-end'>
          <Text fw={700} fz={rem(16)} lh={rem(16)}>
            {shortenNumber(data?.balance?.teaCount ?? 0)}
          </Text>

          <ActionIcon mr={rem(2)} size={18} variant="transparent">
            <IconPlus />
          </ActionIcon>
        </Flex>
      </Flex>

      <Flex align='center' className={itemContainerStyles} {...qa("energy-balance")}>
        <IconCigarettes className={iconStyles} />

        <Flex align="center" gap={rem(4)} justify='flex-end'>
          <Text fw={700} fz={rem(16)} lh={rem(16)}>
            {shortenNumber(data?.balance?.сigarettesCount ?? 0)}
          </Text>

          <ActionIcon mr={rem(2)} size={18} variant="transparent">
            <IconPlus />
          </ActionIcon>
        </Flex>
      </Flex>
    </Flex>
  );
};
