import {
  useIsConnectionRestored,
  useTonWallet,
} from "@tonconnect/ui-react";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { useAppStore } from "~/app/config";
import { tonBackend } from "~/shared/backends";

export function useBackendAuth() {
  const { tonWalletStore } = useAppStore();
  const isConnectionRestored = useIsConnectionRestored();
  const wallet = useTonWallet();
  const { backendToken } = tonWalletStore.state;

  useEffect(() => {
    if (!isConnectionRestored) {
      return;
    }

    if (!backendToken && wallet) {
      Cookies.remove("Wallet");

      tonBackend.verify(undefined, {
        address: {
          address: wallet.account.address,
          publicKey: wallet.account.publicKey ?? "",
          walletStateInit: wallet.account.walletStateInit,
        },
      }).then((res) => {
        const token = res.data.response;
        if (!token) {
          return;
        }

        tonWalletStore.setToken(token);
        Cookies.set("Wallet", token, {
          expires: 5,
        });
      });
    }
  }, [wallet, isConnectionRestored]);
}
