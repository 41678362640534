import { useEffect, useMemo, useReducer, useRef } from "react";
import {
  currentProgressLineStyles,
} from "./styles.css";

type Props = {
  percent: number,
  startColor: string,
  endColor: string,
  index: number
}

export const CurrentProgressLine = ({ percent, startColor, endColor, index }: Props) => {
  const ref = useRef<SVGSVGElement>(null);
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const pathWidth = useMemo(() => {
    if (!ref.current) {
      return {
        H: 0,
        W: 0,
        L: 0,
      };
    }

    return {
      H: ref.current?.width.baseVal.value * (100 / Math.max(percent, 1)) / 1.054 + 24,
      W: ref.current?.width.baseVal.value * (100 / Math.max(percent, 1)) + 24,
      L: ref.current?.width.baseVal.value * (100 / Math.max(percent, 1)) + 24,
    };
  }, [ref, ref.current, percent]);

  useEffect(() => {forceUpdate();}, []);

  return (
    // eslint-disable-next-line max-len
    <svg className={currentProgressLineStyles[index]} fill="none" height="6" ref={ref} viewBox={`0 0 ${pathWidth.W} 6`} width={`calc(${percent}% + 24px)`} xmlns="http://www.w3.org/2000/svg">
      {/* eslint-disable-next-line max-len */}
      <path clipRule="evenodd" d={`M${pathWidth.H} 0H0V8H${pathWidth.H}H${pathWidth.H}L${pathWidth.L} 0H${pathWidth.H}Z`} fill={`url(#paint${index}_linear_123_3286)`}
        fillRule="evenodd" />
      <defs>
        {/* eslint-disable-next-line max-len */}
        <linearGradient gradientUnits="userSpaceOnUse" id={`paint${index}_linear_123_3286`} x1="0" x2={`calc(${percent}% + 24px)`} y1="4" y2="4">
          <stop stopColor={startColor} />
          <stop offset="1" stopColor={endColor} />
        </linearGradient>
      </defs>
    </svg>
  );
};
