import { postEvent } from "@telegram-apps/sdk-react";
import { PropsWithChildren, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { qa } from "@isource-dev/testing";
import { useCheckUser } from "~/domains/game/entities/player";
import { RootGamesPage } from "~/domains/game/pages/Root/root.page";
import { GameRoutes } from "~/shared/routes";
import { layoutStyles } from "./styles.css";
import { TabNavigation } from "./components/TabBar";
import { TopNavbar } from "./components/TopNavbar";
import { Page } from "./components/Page";

export const DefaultLayout = ({ children }: PropsWithChildren) => {
  const location = useLocation();

  useCheckUser();

  useEffect(() => {
    postEvent("web_app_expand");
    postEvent("web_app_setup_swipe_behavior", {
      allow_vertical_swipe: false,
    });
    postEvent("web_app_setup_main_button", {
      is_visible: false,
    });
  }, []);

  return (
    <div {...qa("default-layout")} className={layoutStyles}>
      <Outlet />
      <TopNavbar />
      <Page>
        <div style={{
          position: "absolute",
          zIndex: -1,
          opacity: location.pathname === GameRoutes.Root ? 1 : 0,
          height: "100%",
          width: "100%",
        }}>
          <RootGamesPage />
        </div>
        {children}
      </Page>
      <TabNavigation />
    </div>
  );
};
