import { REFERRAL_KEY_ID } from "~/shared/constants";

export const parseReferralCodeFromURL = (code: string | undefined): string | null => {
  if (!code) {
    return null;
  }

  if (!code.startsWith(REFERRAL_KEY_ID)) {
    return null;
  }

  return code.split(REFERRAL_KEY_ID).at(-1) ?? null;
};
