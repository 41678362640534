import { enableStaticRendering } from "mobx-react-lite";
import { TonWalletStore } from "~/domains/ton-auth/store";
import { PlayerStore } from "~/domains/game/store";

enableStaticRendering(typeof window === "undefined");

export class RootStore {
  tonWalletStore: TonWalletStore;
  playerStore: PlayerStore;

  constructor() {
    this.tonWalletStore = new TonWalletStore(this);
    this.playerStore = new PlayerStore(this);
  }
}
