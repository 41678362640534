import { PropsWithChildren } from "react";
import { rem, Title as MantineTitle, TitleProps as MantineTitleProps } from "@mantine/core";
import clsx from "clsx";
import { titleStyles } from "./styles.css";

type Props = PropsWithChildren<MantineTitleProps> & {
  /**
   * @default default
   */
  variant?: "default" | "inverted"
}

export const Title = ({
  children,
  variant = "default",
  ...rest
}: Props) => {
  return (
    <MantineTitle
      fz={rem(40)}
      h='fit-content'
      lh={rem(26)}
      w='fit-content'
      {...rest}
      className={clsx(titleStyles[variant], rest.className)}
      lts={rem(-0.02)}
      order={1}
      style={rest.style}
      tt="uppercase"
    >
      {children}
    </MantineTitle>
  );
};
