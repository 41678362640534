import { IntlShape } from "react-intl";
import { rem } from "@mantine/core";
import { CSSProperties } from "react";
import { ActivitiesRoutes, GameRoutes, MarketRoutes } from "~/shared/routes";
import IconMainTab from "./assets/main-tab-icon.png";
import BgMainTab from "./assets/main-tab-bg.png";

import IconInventoryTab from "./assets/inventory-tab-icon.png";
import BgInventoryTab from "./assets/inventory-tab-bg.png";

import IconActivitiesTab from "./assets/activities-tab-icon.png";
import BgActivitiesTab from "./assets/activities-tab-bg.png";

import IconMarketTab from "./assets/market-tab-icon.png";
import BgMarketTab from "./assets/market-tab-bg.png";

import SpikesInventoryIcon from "./assets/spikes-inventory.svg";
import SpikesActivitiesIcon from "./assets/spikes-activities.svg";
import SpikesMarketIcon from "./assets/spikes-market.svg";

export enum BottomNavigationEnum {
  TabOne = "main",
  TabTwo = "inventory",
  TabThree = "activities",
  TabFour = "market"
}

type TabMeta = {
  id: BottomNavigationEnum;
  text: string;
  path: string;
  bg: string;
  icon: string;
  tabInlineStyles: CSSProperties
  textInlineStyles: CSSProperties
  iconInlineStyles: CSSProperties
};

export const getTabs = (intl: IntlShape): TabMeta[] => [
  {
    id: BottomNavigationEnum.TabOne,
    text: intl.formatMessage({ id: "tabbar.tab-1" }),
    path: GameRoutes.Root,
    icon: IconMainTab,
    bg: BgMainTab,
    tabInlineStyles: {
      width: rem(88),
    },
    textInlineStyles: {
      transform: "rotate(-3deg)",
      left: rem(-1),
    },
    iconInlineStyles: {
      width: rem(92),
      height: rem(90),
      top: rem(-22),
      left: rem(-2),
    },
  },
  {
    id: BottomNavigationEnum.TabTwo,
    text: intl.formatMessage({ id: "tabbar.tab-2" }),
    path: GameRoutes.Inventory,
    icon: IconInventoryTab,
    bg: BgInventoryTab,
    tabInlineStyles: {
      width: rem(104),
    },
    textInlineStyles: {
      transform: "rotate(2deg)",
    },
    iconInlineStyles: {
      width: rem(120),
      height: rem(120),
      top: rem(-36),
      right: rem(-10),
    },
  },
  {
    id: BottomNavigationEnum.TabThree,
    text: intl.formatMessage({ id: "tabbar.tab-3" }),
    path: ActivitiesRoutes.Root,
    icon: IconActivitiesTab,
    bg: BgActivitiesTab,
    tabInlineStyles: {
      width: rem(66),
    },
    textInlineStyles: {
      transform: "rotate(-3deg)",
    },
    iconInlineStyles: {
      width: rem(110),
      height: rem(110),
      top: rem(-36),
      right: rem(-20),
    },
  },
  {
    id: BottomNavigationEnum.TabFour,
    text: intl.formatMessage({ id: "tabbar.tab-4" }),
    path: MarketRoutes.Root,
    icon: IconMarketTab,
    bg: BgMarketTab,
    tabInlineStyles: {
      width: rem(88),
    },
    textInlineStyles: {
      transform: "rotate(2deg)",
    },
    iconInlineStyles: {
      width: rem(125),
      height: rem(125),
      top: rem(-40),
      right: rem(-20),
    },
  },
];

export const SPIKES_INFO_MAP: Record<BottomNavigationEnum, CSSProperties> = {
  [BottomNavigationEnum.TabOne]: {},
  [BottomNavigationEnum.TabTwo]: {
    backgroundImage: `url(${SpikesInventoryIcon})`,
    position: "absolute",
    width: "130%",
    height: "140%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "80%",
    backgroundPosition: "center",
    top: rem(-22),
    left: rem(-18),
    filter: "contrast(0.5)",
  },
  [BottomNavigationEnum.TabThree]: {
    backgroundImage: `url(${SpikesActivitiesIcon})`,
    position: "absolute",
    width: "140%",
    height: "140%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "80%",
    backgroundPosition: "center",
    top: rem(-18),
    left: rem(-14),
    filter: "contrast(0.5)",
    rotate: "-173deg",
    transform: "scaleX(-1)",
  },
  [BottomNavigationEnum.TabFour]: {
    backgroundImage: `url(${SpikesMarketIcon})`,
    position: "absolute",
    width: "140%",
    height: "140%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "80%",
    backgroundPosition: "center",
    top: rem(-15),
    left: rem(-18),
    filter: "contrast(0.5)",
  },
};
