import { Drawer } from "vaul";
import { Avatar, Box, Flex, rem } from "@mantine/core";
import { FormattedMessage } from "react-intl";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { shortenNumber } from "@isource-dev/formatters";
import { useQuery } from "@tanstack/react-query";
import { qa } from "@isource-dev/testing";
import { FixedSizeList as VirtualizedList } from "react-window";
import { Button, Title } from "~/shared/components";
import { referralBackend } from "~/shared/backends";
import { QueryKeysEnum } from "~/shared/api";
import { nestedPopupContentStyles, basePopupOverlayStyles } from "../styles.css";
import {
  containerStyles,
  friendNameBadge,
  gainedContainerStyles,
  noFriendsText,
  profilePicStyles,
  profitStyles,
  totalProfitContainerStyles,
  totalProfitFlexStyles,
} from "./styles.css";

type Props = {
  onSnapChange: (max: boolean) => void
  moveToFinalSnap?: boolean;
}

const INITIAL_SNAP = .7;
const MAX_SNAP = .95;

const PADDING_TOP_SIZE = 24;

// const mkReferrals = faker.helpers.multiple(faker.internet.userName, { count: 30 }).map((name, i) => ({
//   referralTelegramId: name,
//   cigarettesCount: 1,
//   id: i,
//   referralLvl: 2,
//   cigarettesPerHour: 3,
// }));

export const InvitedFriends = ({ onSnapChange, moveToFinalSnap }: Props) => {
  const [snap, setSnap] = useState<number | string | null>(INITIAL_SNAP);
  const [height, setHeight] = useState(600);

  const contentRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  const { data } = useQuery({
    queryFn: async () => {
      const { data } = await referralBackend.getUserReferralsInfo();
      return data.response;
    },
    queryKey: [QueryKeysEnum.Referral],
  });

  useEffect(() => {
    if (!listRef.current)
      return;

    const resizeObserver = new ResizeObserver(() => {
      setHeight(listRef.current?.offsetHeight ?? 600);
    });

    resizeObserver.observe(listRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  useEffect(() => {
    onSnapChange(snap === MAX_SNAP);
  }, [snap]);

  useEffect(() => {
    if (moveToFinalSnap && contentRef.current && listRef.current) {
      listRef.current.style.display = "none";
      contentRef.current.removeAttribute("style");
      setTimeout(() => {
        contentRef.current!.style.transition = "all 0.5s cubic-bezier(0.32, 0.72, 0, 1);";
        contentRef.current!.style.bottom = "-100vh";
      });
    }
  }, [moveToFinalSnap, contentRef.current, listRef.current]);

  return (
    // eslint-disable-next-line react/no-unknown-property
    <div vaul-drawer-wrapper="">
      <Drawer.NestedRoot
        noBodyStyles
        open
        activeSnapPoint={snap}
        dismissible={false}
        setActiveSnapPoint={setSnap}
        snapPoints={[INITIAL_SNAP, MAX_SNAP]}
      >
        <Drawer.Portal>
          <Drawer.Overlay className={basePopupOverlayStyles} />

          <Drawer.Content {...qa("invited-popup")} className={nestedPopupContentStyles} ref={contentRef}>
            <Flex bottom={rem(-30)} direction='column' h="100%" pos="relative">
              <Title left={rem(28)} pos="relative" style={{ transform: "rotate(-4deg)" }} w={rem(100)}>
                <FormattedMessage id="referral-popup.nested-title" />
              </Title>

              <Flex align="center" className={totalProfitFlexStyles} justify="flex-end">
                <Flex align='center'>
                  <div className={totalProfitContainerStyles}>
                    <span>PROFIT:&nbsp;&nbsp;</span>
                    <Title display='inline' fz={rem(20)} style={{ transform: "none" }}>
                      {shortenNumber(data?.cigarettesEarned ?? 0, "en")}
                    </Title>
                  </div>

                  <Button variant='green'>
                    <FormattedMessage id='invited-friends.claim' />
                  </Button>
                </Flex>
              </Flex>

              <Flex
                direction="column"
                flex={snap === INITIAL_SNAP ? 0.68 : 0.9}
                gap="md"
                h="100%"
                mt="md"
                ref={listRef}
              >
                {
                  data?.referrals && data?.referrals.length > 0 ? (
                    <VirtualizedList
                      height={height}
                      itemCount={data.referrals.length + 1.6}
                      itemSize={130}
                      width='100%'
                      style={snap === INITIAL_SNAP ? {
                        overflow: "hidden",
                      }: { overflow: "auto" }}
                    >
                      {({ index, style }) => {
                        if (!data.referrals[index]) {
                          return null;
                        }

                        return (
                          <FriendItem
                            friendName={data.referrals[index].referralTelegramId}
                            gained={data.referrals[index].cigarettesCount}
                            key={data.referrals[index].id}
                            level={data.referrals[index].referralLvl}
                            profilePicURL=""
                            profit={data.referrals[index].cigarettesPerHour}
                            style={style}
                          />
                        );
                      }}
                    </VirtualizedList>
                  ) :
                    <Box
                      className={noFriendsText}
                      m='auto'
                      pos='relative'
                      top={snap === INITIAL_SNAP ? rem(-130) : 0}
                      w='fit-content'
                    >
                      <FormattedMessage id='invited-friends.no-friends' />
                    </Box>
                }
              </Flex>
            </Flex>
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.NestedRoot>
    </div>
  );
};

type FriendItemProps = {
  friendName: string;
  level: number;
  profit: number;
  gained: number;
  profilePicURL: string;
  style: CSSProperties;
}

const FriendItem = ({ friendName, level, profilePicURL, profit, gained, style }: FriendItemProps) => {
  return (
    <Flex direction="column" style={{
      ...style,
      top: `${Number(style.top ?? 0) + PADDING_TOP_SIZE}px`,
    }}>
      <Box className={containerStyles}>
        <Avatar
          alt={`Profile picture of friend ${friendName}`}
          className={profilePicStyles}
          left={rem(56)}
          pos='relative'
          src={profilePicURL}
          top={rem(-20)}
        />
        <Flex align="center" justify='space-between'>
          <Box>
            <Flex bottom={rem(28)} gap={rem(8)} left={rem(30)} pos='relative' style={{ zIndex: 2 }}>
              <span className={friendNameBadge}>@{friendName}</span>

              <Title fz={rem(20)} style={{ transform: "none" }}>
                <FormattedMessage id='invited-friends.level' values={{ level }} />
              </Title>
            </Flex>

            <Box bottom={rem(24)} className={profitStyles} left={rem(28)} pos='relative' w='fit-content'>
              <FormattedMessage id='invited-friends.profit' values={{ amount: profit }} />
            </Box>
          </Box>

          <Box className={gainedContainerStyles} left={rem(-20)} pos='relative' top={rem(-25)}>
            {gained}
          </Box>
        </Flex>
      </Box>

      <Box mt={rem(-6)}>
        <Button variant='skewed'>
          <FormattedMessage id='invited-friends.claim' />
        </Button>
      </Box>
    </Flex>
  );
};
