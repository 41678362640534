import { MantineVars, themeToVars } from "@mantine/vanilla-extract";
import { createTheme, rem } from "@mantine/core";

type CustomTheme = {
  other: {
    darkBlue: string;
    paleBlue: string;
    pageXOffset: string;
    baseBlue: string;
    buttonBounce: string;
  }
};

export const DEFAULT_PAGE_PADDING = rem(16);

export const theme = createTheme({
  fontFamily: "Alumni Sans, sans-serif",
  fontFamilyMonospace: "Victor Mono, monospace",
  breakpoints: {
    xs: "0px",
    sm: "375px",
    md: "414px",
    lg: "600px",
  },
  other: {
    darkBlue: "#040611CC",
    baseBlue: "#11223F",
    paleBlue: "#26415F",
    buttonBounce: "translateY(3px)",
    pageXOffset: `0 ${DEFAULT_PAGE_PADDING}`,
  },
});

export const vars: MantineVars & CustomTheme = {
  ...themeToVars(theme),
  other: theme.other as any,
};
