import { Box, Flex, Progress, rem } from "@mantine/core";
import { observer } from "mobx-react-lite";
import { qa } from "@isource-dev/testing";
import { useMemo } from "react";
import { useAppStore } from "~/app/config";
import { OutlineText, Title } from "~/shared/components";
import { getLevelProgress } from "~/domains/game/entities/player/components/Lantern/lib";
import IconStar from "~/shared/assets/star.svg?react";
import {
  levelContainerStyles,
  levelStyles,
  nickNameStyles,
  progressContainerStyles, progressRootStyles,
  progressStyles, starIconStyles,
} from "./styles.css";

const SAFE_NICK_LENGTH = 12;

export const PlayerLantern = observer(() => {
  const { playerStore } = useAppStore();

  const { user } = playerStore.state;

  const getNickNameFontSize = useMemo(() => {
    if ((user?.telegramId?.length ?? -1) <= SAFE_NICK_LENGTH) {
      return rem(28);
    }

    const coef = 28 / (user?.telegramId?.length ?? 1);
    const fontSize = 10 * coef;

    return rem(Math.max(fontSize, 18));
  }, [user?.userName]);

  return (
    <Flex flex={1} justify="space-between">
      <Box className={levelContainerStyles} h={rem(80)} w={rem(75)}>
        <OutlineText bottom={rem(-48)} className={levelStyles} fz={rem(24)} pos='relative'>
          LVL.{user?.userLevel?.level}
        </OutlineText>
      </Box>

      <Flex direction='column' justify='flex-end' pos='relative' py={rem(16)}>
        <div {...qa("player-username")}>
          <Title
            className={nickNameStyles}
            fz={getNickNameFontSize}
            left={rem(-20)}
            lh={getNickNameFontSize}
            pos='absolute'
            top={rem(14)}
          >
            {user?.telegramId}
          </Title>
        </div>

        <Flex
          bottom={rem(-12)}
          className={progressContainerStyles}
          direction='column'
          gap={rem(4)}
          left={rem(-16)}
          pos="relative"
        >
          <IconStar className={starIconStyles} />

          <Flex bottom={rem(-2)} justify='flex-end' mr={rem(16)} pos="relative">
            <Title fz={rem(16)} lh={rem(16)}>
              {user?.userLevel?.exp} / {user?.userLevel?.experienceToNextLevel}
            </Title>
          </Flex>

          <Progress
            ml={rem(12)}
            pos='relative'
            radius='xs'
            value={getLevelProgress(user?.userLevel?.exp, user?.userLevel?.experienceToNextLevel)}
            w={rem(90)}
            classNames={{
              root: progressRootStyles,
              section: progressStyles,
            }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
});
