import { makeAutoObservable } from "mobx";
import { RootStore } from "~/app/config";
import { IStoreUnit } from "~/shared/types";

type SetTonDataCommand = {
  /**
   * Адрес кошелька пользователя в сети TON
   * @example EQAWzEKcdnykvXfUNouqdS62tvrp32bCxuKS6eQrS6ISgcLo
   */
  address: string,
  /**
   * Отображаемое имя пользователя в Telegram
   * @example Саша
   */
  name: string,
  /**
   * Никнейм пользователя
   * @example sashafromlibertalia
   */
  username: string,
  referral_code?: string,
};

export class TonWalletStore implements IStoreUnit {
  root: RootStore;

  private walletAddress: string | null = null;
  private name: string | null = null;
  private username: string | null = null;
  private referralCode: string | null = null;

  private backendToken: string | null = null;

  constructor(root: RootStore) {
    this.root = root;
    makeAutoObservable(this);
  }

  setData(dto: SetTonDataCommand) {
    this.name = dto.name ?? null;
    this.walletAddress = dto.address;
    this.username = dto.username ?? null;
    this.referralCode = dto.referral_code ?? null;
  }

  setToken(token: string | null) {
    this.backendToken = token;
  }

  get state() {
    return {
      walletAddress: this.walletAddress,
      name: this.name,
      username: this.username,
      referralCode: this.referralCode,
      backendToken: this.backendToken,
    };
  }

  reset() {
    this.name = null;
    this.walletAddress = null;
    this.username = null;
    this.referralCode = null;
    this.backendToken = null;
  }
}
